/* Main container for the app */
.main-container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Allow the height to grow with content */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Background video styling */
.background-video {
  position: fixed; /* Keep the video fixed in the background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Behind all other elements */
}

/* Content container */
.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the content covers the viewport */
  width: 100%; /* Avoid content overflow */
  padding: 20px; /* Add padding for spacing */
  text-align: center;
  border-radius: 10px;
  margin: auto;
  max-width: 80%;
  transition: background-color 0.3s, color 0.3s;
}

/* Light mode styles */
/*
.light-mode .content {
  background: rgba(2, 1, 1, 0.959); 
  color: #000 !important; 
}

.light-mode h1, .light-mode p {
  color: #000 !important; 
  text-shadow: none; 
}
*/

/* Dark mode styles */
.dark-mode .content {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: #fff; /* Light text for dark mode */
}

.dark-mode h1, .dark-mode p {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7); /* Shadow for better contrast */
}

/* Responsive styling for small screens */
@media screen and (max-width: 256px) {
  .content {
    padding: 10px; /* Adjust padding for smaller screens */
    max-width: 90%; /* Allow more width on small screens */
  }
}
